import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

document.addEventListener("DOMContentLoaded", function() {
    let sections = [];
    let previews = document.querySelectorAll('.recipe-previews .featured-wrapper');
    let recipesList = document.querySelector('.recipes-list');
    if(previews && previews.length > 0) {
        previews.forEach(function(preview, index) {
            sections.push(preview);
        });
    }
    if(recipesList) {
        sections.push(recipesList);
    }

    if(sections && sections.length > 0) {
        $(window).scroll(function (e) {
            let scrollTop = $(e.target).scrollTop();
            let firstTop = $(sections[0]).offset().top;
            let lastTop = $(sections[sections.length - 1]).offset().top;

            sections.forEach(function(el, index) {
                let $section = $(el);
                let offsetTop = $section.offset().top;
                let height = $section.outerHeight();
                let $figure = $section.find('.parallax-container');

                if($figure) {
                    if (scrollTop > firstTop && scrollTop <= lastTop) {
                        let diff = scrollTop + height - offsetTop;

                        $figure.addClass('fixed');
                        if (index > 0) {
                            $figure.css('height', '' + Math.min(diff, height) + 'px');
                        }

                    } else {
                        $figure.removeClass('fixed');
                        $figure.css('height', '');
                    }
                }
            });
        });

        sections.forEach(function(section, index) {
            let heading = section.querySelector('h2.anim-in');
            let recipeLink = gsap.utils.toArray(section.querySelector("a.anim-in"));
            let recipeTeases = gsap.utils.toArray(section.querySelectorAll("ul.anim-in li"));
            let scrollStart = "bottom-=400";
            if (section.classList.contains('recipes-list')) {
                scrollStart = "bottom-=200";
            }
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: `top ${scrollStart}`,
                },
                defaults: {
                    duration: 0.5, ease: "power2.out"
                }
            });

            if(recipeLink && recipeLink.length) {
                tl.fromTo(recipeLink,
                    {
                        opacity: 0,
                        y: 50
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        y: 0
                    }
                );
            }
            if(recipeTeases && recipeTeases.length) {
                ScrollTrigger.batch(recipeTeases, {
                    start: "top bottom-=100",
                    onEnter: (batch) => {
                        batch.forEach((element, index) => {
                            gsap.to(element, {
                                duration: 0.5,
                                opacity: 1,
                                y: 0,
                                delay: index * 0.5, // This creates the stagger effect
                            });
                        });
                    },
                });
            }
        });
    }

    // Recipes search and pagination
    let recipesSearch = document.querySelector('.recipes-list #search-input');

    if(recipesSearch) {
        let container = document.querySelector('.recipes-list .recipes-wrapper');
        let recipesAttr = container.getAttribute('data-featured') ?? null;
        let currentLanguage = container.getAttribute('data-lang');
        let featuredRecipes = [];
        if(recipesAttr) {
            featuredRecipes = recipesAttr.split(",");
        } 
        let searchInput = recipesSearch.querySelector('#recipes-search');
        let searchBtn = recipesSearch.querySelector('button');
        let deleteBtn = recipesSearch.querySelector('label >svg');
        let paginationNumbers = document.querySelectorAll('.pagination li a');
        let currentPage = 1;
        let searchText = "";
        let initialOffsetTop = document.querySelector('.recipes-list').getBoundingClientRect().top + window.scrollY;
        
        searchInput.addEventListener('keyup', function (e) {
            searchText = e.target.value.toLowerCase();
            currentPage = 1;
            if(searchText != '') {
                deleteBtn.classList.add('show');
                searchBtn.classList.add('hide');
            }
            else {
                searchBtn.classList.remove('hide');
                deleteBtn.classList.remove('show');
            }
            getRecipes(searchText, currentPage, featuredRecipes, false);
        });
        
        deleteBtn.addEventListener('click', function(e) {
            searchText = "";
            searchInput.value = '';
            searchBtn.classList.remove('hide');
            deleteBtn.classList.remove('show');
            currentPage = 1;
            getRecipes(searchText, currentPage, featuredRecipes, true);
        });

        function setPagination() {
            let paginationNumbers = document.querySelectorAll('.pagination li a');
            if(paginationNumbers && paginationNumbers.length > 0) {
                paginationNumbers.forEach(page => {
                    page.parentElement.classList.remove('current');
                    page.addEventListener('click', function(e) {
                        e.preventDefault();
                        currentPage = page.getAttribute('data-slug');
                        getRecipes(searchText, currentPage, featuredRecipes, true);
                    });
                });
                container.querySelector('.pagination li a[data-slug="' + currentPage + '"]').parentElement.classList.add('current');
                if(currentPage > 7) {
                    container.querySelectorAll('.pagination li')[6].parentElement.classList.add('current');
                }
                else {
                    container.querySelectorAll('.pagination li')[currentPage - 1].parentElement.classList.add('current');
                }
            }
        }
        if(paginationNumbers && paginationNumbers.length > 0) {
            setPagination();
        }

        function getRecipes(search, currentPage, featuredRecipes, setOffsetTop) {
            let data = new FormData();
            data.append('action', 'get_recipes');
            data.append('search', search);
            data.append('page', currentPage);
            data.append('featured', featuredRecipes);
            data.append('lang', currentLanguage);
        
            $.ajax({
                url: sterling_scripts.ajax_url,
                type: 'POST',
                data: data,
                cache: false,
                processData: false,
                contentType: false,
                error: function (data) {
                    console.error(data);
                },
                success: function (res) {
                    container.innerHTML = res;
                    setPagination();
                    if(setOffsetTop) {
                        $('html, body').animate({
                            scrollTop: initialOffsetTop
                        });
                    }
                }
            });
        }
    }
});