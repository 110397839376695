import { intersectionObserve } from "./_intersection-observer";

function loadTarget(target) {
    if (target.classList && target.classList.contains('loaded')) {
        return;
    }

    if (target.tagName === 'IMG') {
        target.onload = function() {
            showElement(target);
        }
        target.src = target.getAttribute('data-src');
        target.classList.add('loaded');
    }
}

function showElement(element) {
    if (element.classList) {
        if (!element.classList.contains('show')) {
            element.classList.add('show');
        }
    } else {
        element.className += ' show';
    }
}

let images = document.querySelectorAll('img.lazy');
intersectionObserve(images, 200, 0, (image, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            loadTarget(el);
        } 
    })(image);
});