import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function() {
    const zoomImages = document.querySelectorAll('.zoom-image');
    const heroImg = document.querySelector('.hero-img');

    // Zoom for hero img
    if (heroImg) {
        gsap.fromTo(heroImg,
            { scale: 1 },
            {
                scale: 1.2,
                ease: "none",
                scrollTrigger: {
                    trigger: heroImg,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                    onUpdate: (self) => {
                        const progress = self.progress;
                        const scale = gsap.utils.interpolate(1, 1.2, progress);
                        gsap.set(heroImg, { scale: scale });
                    }
                }
            }
        );
    }

    // Zoom for other images
    zoomImages.forEach(img => {
        const container = img.closest('.image-wrapper');

        gsap.fromTo(img,
            { scale: 1 },
            {
                scale: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: container,
                    start: "top bottom",
                    end: "50% top", // This means the animation will end when 50% of the element is above the viewport
                    scrub: true,
                    onUpdate: (self) => {
                        const progress = self.progress;
                        const scale = gsap.utils.interpolate(1, 1.2, progress);
                        gsap.set(img, { scale: scale });
                    }
                }
            }
        );
    });
});