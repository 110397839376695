import { gsap } from "gsap";

const breakpoint = 1024;
let header = document.querySelector('header');

if(header) {
    const wrapperContent = header.querySelector('.wrapper').innerHTML;
    let lastScrollTop = 0;

    // Menu on scroll
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();
        if (scrollTop > 2) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                header.classList.add('sticky', 'animate', 'show')
            } else {
                header.classList.add('sticky');
                header.classList.remove('show');
            }
        }

        lastScrollTop = scrollTop;
    });

    if(window.innerWidth < breakpoint) {
        setMobileNav();
    }
    else {
        setDesktopNav();
    }

    window.addEventListener('resize', function() {
        
        header.querySelector('.wrapper').innerHTML = wrapperContent;

        if(window.innerWidth < breakpoint) {
            setMobileNav();
        }
        else {
            if(document.querySelector("body").classList.contains('no-scroll')) {
                document.querySelector("body").classList.remove('no-scroll');
            }
            setDesktopNav();
        }

    });

    function setMobileNav() {
        const button = document.querySelector(".menu-button");
        const closeButton = document.querySelector(".menu-close");
        const navBar = document.querySelector("header .menu-content")
        const header = document.querySelector("header")
        const body = document.querySelector("body");
        const parent = document.querySelectorAll(".menu-item-has-children >a")
        const arrow = document.querySelectorAll(".menu-item-has-children >svg")

        button.onclick = function toggleMenu() {
            navBar.classList.add("show", "anim");
            button.classList.add("hide");
            setTimeout(function() {
                header.classList.add('show-menu');
            }, 300);
            header.querySelector(".wrapper").classList.add('anim');
            body.classList.add('no-scroll');

            // Hides dropdown when menu is hidden
            parent.forEach(function (ele) {
                ele.classList.remove("show-ul");
            });
        };

        closeButton.onclick = function closeMenu() {
            navBar.classList.remove("anim");
            setTimeout(function() {
                navBar.classList.remove("show");
            }, 300);
            header.querySelector(".wrapper").classList.remove('anim');
            button.classList.remove("hide");
            header.classList.remove('show-menu');
            body.classList.remove('no-scroll');
        }


        //dropdown
        /**
         * check if parent has child with classname
         * on click add or remove class
        */
        parent.forEach(function (ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    window.location = e.target.href;
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
        arrow.forEach(function(ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    ele.parentNode.classList.remove("show-ul");
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
    }

    function setDesktopNav() {
        const links = document.querySelectorAll(".menu-item-has-children >a");
        const descendants = document.querySelectorAll(" .menu-item-has-children > ul > li > a");

        descendants.forEach(descendant => {
            descendant.addEventListener('focus', (event) => {
                const grandParent = descendant.parentElement.parentElement.parentElement;
                grandParent.classList.add("show-ul");
            });

            descendant.addEventListener('blur', (event) => {
                const grandParent = descendant.parentElement.parentElement.parentElement;
                grandParent.classList.remove("show-ul");
            });
        });

    links.forEach(link => {
            link.addEventListener('focus', (event) => {
                link.parentNode.classList.add("show-ul");
            });
        
            link.addEventListener('blur', (event) => {
                link.parentNode.classList.remove("show-ul");
            });
        });
    }

    let iconLink = header.querySelector('.menu-icon');
    if(iconLink) {
        let menuIcon = iconLink.querySelector('svg');
        let iconPathLeft = menuIcon.querySelectorAll('path')[0];
        let iconPathRight = menuIcon.querySelectorAll('path')[1];
        let tl = gsap.timeline({
            defaults: {
                duration: 0.6, 
                ease: "power2.out",
            }
        });
        tl.to(iconPathLeft,
            {
                y: -2.5,
                x: 11
            },
            'start'
        );
        tl.to(iconPathRight,
            {
                y: 2.5,
                x: -11
            },
            'start'
        );
        tl.pause();

        iconLink.addEventListener('mouseover', function() {
            tl.duration(0.6);
            tl.play();
        });
        iconLink.addEventListener('mouseleave', function() {
            tl.duration(0.5);
            tl.reverse();
        });
    }
}