import './_body-view-height';
import './_scroll-to.js';
import './header';
import './_search';
import './_lazy-image.js';
//import './_slideshow.js';
import './_tutorials.js';
import './_skip-header.js';
import './_get-posts.js';
import './_map.js';
import './_custom-dashboard.js';
import './_font-animation.js';
import './_hero-frontpage.js';
import './_recipe-previews.js';
import './_image-video.js';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
/* The following plugins are Club GSAP perks */
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger,TextPlugin,ScrollSmoother,SplitText);

window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
import './_image-animation.js';
import './_footer.js';